import {
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { StringParam, useQueryParam } from "use-query-params";

import {
    occasionalDriverFormConfig,
    QuoteFormKey,
    QuoteFormState,
    quoteFormState,
    QuoteLayout,
    TabsLayout,
    VehicleHeading,
} from "@modules/quote";
import { CompletedCard, Navigation } from "@modules/shared/components";
import { OccasionalDrivers, PageNames } from "@modules/shared/enums";
import {
    filterQuotePages,
    findNextInvalidTab,
    getNavigationPages,
} from "@modules/shared/helpers";
import { AppState, appState } from "@modules/shared/state";
import { PageInterface, PageProps, TabsStatus } from "@modules/shared/types";

const getNextTab = (
    tabs: PageInterface[],
    nextPage?: PageInterface,
    currentDriver?: string | null,
) => {
    const nextTab =
        currentDriver === OccasionalDrivers.One
            ? tabs.find(
                  (tab) =>
                      tab.name ===
                      `${PageNames.QuoteOccasionalDriver}${OccasionalDrivers.Two}`,
              )
            : null;
    const nextTabAlt = tabs.find((tab) => tab.name === nextPage?.name);

    return nextTab ?? nextTabAlt;
};

const getPrevTab = (
    tabs: PageInterface[],
    prevPage?: PageInterface,
    currentDriver?: string | null,
) => {
    const prevTab =
        currentDriver === OccasionalDrivers.Two
            ? tabs.find(
                  (tab) =>
                      tab.name ===
                      `${PageNames.QuoteOccasionalDriver}${OccasionalDrivers.One}`,
              )
            : null;

    const prevTabAlt = tabs.find((tab) => tab.name === prevPage?.name);

    return prevTab ?? prevTabAlt;
};

const OccasionalDriver: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const { t } = useTranslation();
    const appData = useRecoilValue<AppState>(appState);
    const [formState, setFormState] = useRecoilState(quoteFormState);
    const [driver] = useQueryParam("driver", StringParam);
    const [formProps, setFormProps] = useState<
        Pick<FormProps<FormValues>, "dirty" | "isValid">
    >({
        isValid: false,
        dirty: false,
    });

    useEffect(() => {
        if (!appData?.InsuranceChoice?.insurance?.type) {
            // Navigate to the product page when there is no insurance choice selected
            // A quote flow can only started when there is a simulation available
            navigate("/");
        }
    }, [appData]);

    const driverName = useMemo(() => {
        const driverOne = formState?.drivers?.occasionalDrivers?.driverOne;
        const driverTwo = formState?.drivers?.occasionalDrivers?.driverTwo;

        if (driver === OccasionalDrivers.One && driverOne) {
            return `${driverOne.firstName} ${driverOne.lastName}`;
        }

        if (driver === OccasionalDrivers.Two && driverTwo) {
            return `${driverTwo.firstName} ${driverTwo.lastName}`;
        }

        return "";
    }, [
        driver,
        formState?.drivers?.occasionalDrivers?.driverOne,
        formState?.drivers?.occasionalDrivers?.driverTwo,
    ]);

    const formNameSpace = useMemo(
        () =>
            driver === OccasionalDrivers.Two
                ? QuoteFormKey.OccasionalDriverTwo
                : QuoteFormKey.OccasionalDriverOne,
        [driver],
    );

    const initialFormValues: QuoteFormState = useMemo(
        () => ({
            ...formState,
            ...(driver === OccasionalDrivers.Two
                ? {
                      occasionalDriverTwo: {
                          ...formState?.occasionalDriverTwo,
                          personalDetails: {
                              ...formState?.occasionalDriverTwo
                                  ?.personalDetails,
                              firstName:
                                  formState?.drivers?.occasionalDrivers
                                      ?.driverTwo?.firstName,
                              lastName:
                                  formState?.drivers?.occasionalDrivers
                                      ?.driverTwo?.lastName,
                              birthDate:
                                  formState?.drivers?.occasionalDrivers
                                      ?.driverTwo?.birthDate,
                          },
                      },
                  }
                : {
                      occasionalDriverOne: {
                          ...formState?.occasionalDriverOne,
                          personalDetails: {
                              ...formState?.occasionalDriverOne
                                  ?.personalDetails,
                              firstName:
                                  formState?.drivers?.occasionalDrivers
                                      ?.driverOne?.firstName,
                              lastName:
                                  formState?.drivers?.occasionalDrivers
                                      ?.driverOne?.lastName,
                              birthDate:
                                  formState?.drivers?.occasionalDrivers
                                      ?.driverOne?.birthDate,
                          },
                      },
                  }),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [driver],
    );

    const handleSubmit = (
        values: FormValues,
        tabs: PageInterface[],
        tabsStatus: TabsStatus,
        nextPage?: PageInterface,
    ): void => {
        updateFormState(values);
        const nextTab = getNextTab(tabs, nextPage, driver);
        const nextInvalidTab = findNextInvalidTab(
            tabsStatus,
            page,
            allPages,
            language,
            driver,
        );

        if (nextInvalidTab) {
            navigate(nextInvalidTab.paths[language]);
            return;
        }
        if (nextTab) {
            navigate(nextTab.paths[language]);
        }
    };

    const updateFormState = (formValues: QuoteFormState): void => {
        const isDriverTwo = driver === OccasionalDrivers.Two;
        const personalDetails = isDriverTwo
            ? {
                  ...formValues.occasionalDriverTwo?.personalDetails,
              }
            : {
                  ...formValues.occasionalDriverOne?.personalDetails,
              };
        setFormState({
            ...formValues,
            drivers: {
                ...formValues.drivers,
                occasionalDrivers: {
                    ...formValues.drivers?.occasionalDrivers,
                    ...(isDriverTwo
                        ? {
                              driverTwo: {
                                  ...formValues.drivers?.occasionalDrivers
                                      ?.driverTwo,
                                  firstName: personalDetails.firstName,
                                  lastName: personalDetails.lastName,
                                  birthDate: personalDetails.birthDate,
                              },
                          }
                        : {
                              driverOne: {
                                  ...formValues.drivers?.occasionalDrivers
                                      ?.driverOne,
                                  firstName: personalDetails.firstName,
                                  lastName: personalDetails.lastName,
                                  birthDate: personalDetails.birthDate,
                              },
                          }),
                },
            },
        });
    };

    const handleOnChange = (
        values: FormValues,
        props: Pick<FormProps<FormValues>, "dirty" | "isValid">,
    ): void => {
        setFormProps(props);
        const formValues = values as QuoteFormState;
        updateFormState(formValues);
    };

    const renderHeaderComponent = useCallback(
        (
            tabs: PageInterface[],
            tabsStatus: TabsStatus,
            nextPage?: PageInterface,
        ) => {
            const showCompleteCard = formProps.dirty
                ? formProps.isValid
                : tabsStatus && tabsStatus[`${page.name}${driver}`]?.isValid;
            const nextTab = getNextTab(tabs, nextPage, driver);
            const nextInvalidTab = findNextInvalidTab(
                tabsStatus,
                page,
                allPages,
                language,
                driver,
            );

            if (showCompleteCard) {
                return (
                    <CompletedCard
                        nextPage={nextInvalidTab ?? nextTab}
                        currentPageText={driverName}
                        language={language}
                    />
                );
            }
        },
        [
            formProps.dirty,
            formProps.isValid,
            page,
            driver,
            driverName,
            language,
            allPages,
        ],
    );

    return (
        <QuoteLayout
            title={t("quote.occasionaldriver.title")}
            page={page}
            allPages={allPages}
            language={language}
        >
            <TabsLayout
                title={t("quote.vehicle.title")}
                description={t("quote.vehicle.description")}
                language={language}
                allPages={allPages}
                activeTabName={page.name}
            >
                {({ tabs, tabsStatus }) => {
                    const filteredTabs = filterQuotePages(allPages, tabs);
                    const { nextPage, prevPage } = getNavigationPages(
                        filteredTabs,
                        page,
                    );
                    const nextTab = getNextTab(filteredTabs, nextPage, driver);
                    const prevTab = getPrevTab(filteredTabs, prevPage, driver);
                    const isLastTab =
                        nextTab?.name === PageNames.QuoteInsurances;

                    return (
                        <>
                            <VehicleHeading
                                subTitle={t("quote.occasionaldriver.title")}
                                title={driverName}
                            />
                            <Form
                                scrollToFieldError
                                errorMessageCardTitle={
                                    t("all.errormessage.title") as string
                                }
                                errorMessageCardSubTitle={
                                    t("all.errormessage.text") as string
                                }
                                formContext={{
                                    t,
                                    nameSpace: formNameSpace,
                                    fieldWrapper: {
                                        optionalLabel: "all.optional",
                                    },
                                }}
                                initialValues={initialFormValues}
                                onSubmit={(values) =>
                                    handleSubmit(
                                        values,
                                        filteredTabs,
                                        tabsStatus,
                                        nextPage,
                                    )
                                }
                                onChange={handleOnChange}
                                fields={occasionalDriverFormConfig.fields}
                                headerComponent={() =>
                                    renderHeaderComponent(
                                        filteredTabs,
                                        tabsStatus,
                                        nextPage,
                                    )
                                }
                                enableReinitialize
                            >
                                <Navigation
                                    t={t}
                                    language={language}
                                    prevPage={prevTab}
                                    nextPage={nextTab}
                                    nextPageBtnText="all.next"
                                    prevPageBtnText="all.previous"
                                    nextColor={isLastTab ? "primary" : "info"}
                                />
                            </Form>
                        </>
                    );
                }}
            </TabsLayout>
        </QuoteLayout>
    );
};

export default OccasionalDriver;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
